"use client";
import Button, { BUTTON_VARIANCE } from "@/components/primitives/button";
import {
  BettorPickSlipPick,
  GameAlignment,
  GameAlignmentType,
  GameBasic,
  GameStatus,
  LineType,
  Odd2,
  RecentPick,
  ScoreboardGame,
  ScoreboardGameBasic,
  TeamBasic,
  isGameClosed,
  isGameException,
  lineToPickType,
  toSportType,
  buildOdd,
} from "common";
import { formatTime, formatOdd } from "@/utils";
import Link from "next/link";
import React, { useContext, useRef } from "react";
import { PickSlipContext } from "@/components/pick/create-pick";
import TeamLogo from "@/components/team/logo";
import { createGameDetailSlug } from "@/utils/url";
import {
  LiveGame,
  formatGameStatus,
  getDriveStatus,
  getInPossession,
  getTotalScores,
  isGameLive,
} from "@/utils/game";
import flip from "./flip.module.css";
import Badge from "@/components/primitives/badge";
import { CreatedPickTooltip } from "@/components/primitives/tooltip";
import GameCardSeriesChip from "./series-chip";
import LiveIndicator from "../live-indicator";
import useLocalDate from "@/utils/useLocalDate";
import Icon from "@/components/primitives/icon";
import { useOddsFormat } from "@/utils/providers/odds-format";
import PickChip from "../detail/pick-chip";
import useCountdown from "@/utils/countdown";

export interface GameCardCompactProps
  extends React.HTMLAttributes<HTMLElement> {
  displayingOdds: LineType;
  game: ScoreboardGame;
  view: "slider" | "grid";
}
function GameCardCompact(props: GameCardCompactProps) {
  const [flipped, setFlipped] = React.useState<boolean>(false);
  const [prevStatus, setPrevStatus] = React.useState<GameStatus>(
    props.game.status
  );
  const [prevId, setPrevId] = React.useState<number | undefined>(undefined);
  const [delayStatus, setDelayStatus] = React.useState<boolean>(false);

  const { date, timeZone } = useLocalDate(props.game.dateTime);

  React.useEffect(() => {
    if (
      prevStatus !== undefined &&
      prevStatus !== props.game.status &&
      props.game.id === prevId
    ) {
      setFlipped(true);
      setDelayStatus(true);

      const timer = setTimeout(() => {
        setPrevStatus(props.game.status);
        setDelayStatus(false);
      }, 800);

      return () => clearTimeout(timer);
    } else {
      setPrevId(props.game.id);
      setPrevStatus(props.game.status);
    }
  }, [props.game.status, props.game.id, prevStatus, prevId]);

  React.useEffect(() => {
    if (flipped) {
      const timer = setTimeout(() => {
        setFlipped(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [flipped]);

  // const status = props.game.status;
  const live: LiveGame | undefined = isGameLive(
    props.game,
    delayStatus ? prevStatus : props.game.status
  );

  // const totalScores = getTotalScores(props.game.period);
  const exception = isGameException(props.game.status);
  const closed = isGameClosed(props.game.status);
  const winner: GameAlignment | null =
    !exception && closed
      ? props.game.awayScore > props.game.homeScore
        ? "away"
        : "home"
      : null;
  const countdown = useCountdown(props.game.dateTime);
  let showCountdown = false;
  let startsNow = false;

  let days = Number(countdown.days);
  let hours = Number(countdown.hours);
  let minutes = Number(countdown.minutes);
  let seconds = Number(countdown.seconds);
  if (hours < 1 && days < 1 && !live && !props.game.closed) {
    showCountdown = true;
    if (hours === 0 && minutes === 0 && seconds === 0) {
      startsNow = true;
    }
  }
  const driveStatus = getDriveStatus(props.game);

  return (
    <Link
      href={createGameDetailSlug(props.game.league, props.game.id)}
      data-prevent-nprogress={true}
      prefetch={false}
      className="relative"
    >
      <div
        className={`${
          props.view === "grid"
            ? "scale-[0.80] min-[370px]:scale-[0.825] min-[380px]:scale-[0.85] min-[390px]:scale-[0.875] min-[400px]:scale-[0.9] min-[410px]:scale-[0.925] min-[420px]:scale-[0.95] min-[440px]:scale-100"
            : undefined
        } ${
          props.view === "slider" ? "mr-4" : undefined
        } origin-top-left w-[200px] h-[132px] rounded-xl ${flip.card}`}
      >
        <div
          className={`${flip.cardInner} ${flipped ? flip.flipped : undefined}`}
        >
          <div
            className={`${flip.cardFront} ${
              closed ? "bg-final" : "bg-surface shadow-card"
            } p-4 rounded-xl min-w-[200px] h-[132px] whitespace-nowrap inline-block hover:shadow-elevation-400 hover:bg-surface transition-all duration-300 ease-in-out cursor-pointer`}
          >
            <div className={`${flip.cardContent} inset-0 p-4`}>
              <div className="absolute w-full left-0 right-0 -top-3 flex justify-center">
                <div className="flex items-center justify-center">
                  {props.game.series && (
                    <GameCardSeriesChip
                      info={props.game.series}
                      neutral={closed}
                    />
                  )}
                </div>
              </div>
              <div className="right-0 -bottom-3 left-0 absolute flex justify-center">
                {/* <div className="flex shadow-elevation-200 rounded-full">
                  <Badge
                    color="white"
                    value={props.game.totalPicks}
                    showZero={true}
                    icon={
                      props.game.totalPicks && props.game.totalPicks > 0
                        ? { name: "flame", gradient: "flame" }
                        : undefined
                    }
                    label={`Pick${props.game.totalPicks != 1 ? "s" : ""}`}
                  />
                </div> */}
                <PickChip
                  totalPicks={props.game.totalPicks}
                  color="white"
                  totalComments={props.game.discussion?.totalComments}
                  showAvatars={false}
                  avatars={[]}
                  totalPicksForSale={0}
                  totalBettors={0}
                />
              </div>

              <div
                className={`flex justify-between items-center gap-4 text-grey text-label-lg-medium uppercase mb-2`}
              >
                <div className="flex items-center gap-1">
                  <div className="flex items-center gap-2">
                    {live && !delayStatus ? (
                      <>
                        {/* <div className="indicator-live" /> */}
                        <div className="inline-flex normal-case">
                          {props.game.league === "mlb" ? (
                            <div className="flex items-center gap-2">
                              {`${live.status}, ${live.period.balls || 0}-${
                                live.period.strikes || 0
                              }, ${live.period.outs || 0} Out`}
                              <div className="relative h-full w-[12px]">
                                <div className="absolute inset-0 top-[3px] inline-flex gap-[2px] flex-row flex-wrap w-[12px] rotate-45">
                                  <div
                                    className={`w-[5px] h-[5px] ${
                                      live.period.onSecond
                                        ? "bg-primary-500"
                                        : "bg-secondary-700"
                                    }`}
                                  ></div>
                                  <div
                                    className={`w-[5px] h-[5px] ${
                                      live.period.onFirst
                                        ? "bg-primary-500"
                                        : "bg-secondary-700"
                                    }`}
                                  ></div>
                                  <div
                                    className={`w-[5px] h-[5px] ${
                                      live.period.onThird
                                        ? "bg-primary-500"
                                        : "bg-secondary-700"
                                    }`}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            live.status
                          )}
                        </div>
                        {props.game.league === "nfl" && live ? (
                          <div className="normal-case flex flex-row justify-center items-center gap-2">
                            <div className={`w-px h-3 bg-secondary-700`}></div>
                            <div>{driveStatus?.downAndDistance}</div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : closed ? (
                      <span className="text-dark text-label-lg-bold uppercase">
                        {formatGameStatus(props.game)}
                      </span>
                    ) : (
                      <>
                        {showCountdown ? (
                          <>
                            {startsNow ? (
                              <span>STARTS NOW</span>
                            ) : (
                              <span>
                                STARTS IN {countdown.minutes}:
                                {countdown.seconds}
                              </span>
                            )}
                          </>
                        ) : (
                          <span>
                            {formatTime(date || props.game.dateTime) ||
                              "broken"}{" "}
                            {timeZone}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {live && !delayStatus ? (
                  <LiveIndicator />
                ) : (
                  <div>{props.game.platform}</div>
                )}
              </div>
              <div className="flex flex-col gap-1">
                {/* AWAY ROW */}
                <div
                  className={`flex justify-between items-center gap-2 border-b-[0.6px] ${
                    live && !delayStatus
                      ? "border-secondary-700"
                      : "border-transparent"
                  }`}
                >
                  <div className="flex justify-between items-center gap-2 px-1">
                    <TeamLogo size="sm" team={props.game.awayTeam} />
                    <div className="flex flex-col">
                      <div className="text-title">
                        {props.game.awayTeam.abbreviation}
                      </div>
                      {props.game.status !== "InProgress" && !delayStatus && (
                        <div className="relative text-grey text-label-sm">
                          <div className="left-0 absolute">
                            {props.game.league === "nhl" ? (
                              <>
                                {props.game.awayRecord.wins}-
                                {props.game.awayRecord.losses}-
                                {props.game.awayRecord.overtimeLosses}
                              </>
                            ) : (
                              <>
                                {props.game.awayRecord.wins}-
                                {props.game.awayRecord.losses}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="relative flex justify-center items-center">
                    {/* {live && (
                        <div className="absolute w-px h-5 bg-secondary-700"></div>
                      )} */}

                    <CompactOddsCell
                      alignment="away"
                      winner={
                        winner === "away" ? props.game.awayTeam : undefined
                      }
                      status={delayStatus ? prevStatus : props.game.status}
                      score={props.game.awayScore}
                      game={props.game}
                      odd={buildOdd(
                        props.displayingOdds,
                        GameAlignmentType.Away,
                        props.game.odds
                      )}
                      againstOdd={buildOdd(
                        props.displayingOdds,
                        GameAlignmentType.Home,
                        props.game.odds
                      )}
                    />
                  </div>
                </div>
                {/* HOME ROW */}
                <div className="flex justify-between items-center gap-2">
                  <div className="flex justify-between items-center gap-2 px-1">
                    <TeamLogo size="sm" team={props.game.homeTeam} />
                    <div className="flex flex-col">
                      <div className="text-title">
                        {props.game.homeTeam.abbreviation}
                      </div>
                      {props.game.status !== "InProgress" && (
                        <div className="relative text-grey text-label-sm">
                          <div className="left-0 absolute">
                            {props.game.league === "nhl" ? (
                              <>
                                {props.game.homeRecord.wins}-
                                {props.game.homeRecord.losses}-
                                {props.game.homeRecord.overtimeLosses}
                              </>
                            ) : (
                              <>
                                {props.game.homeRecord.wins}-
                                {props.game.homeRecord.losses}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="relative flex justify-center items-center">
                    {/* {live && (
                        <div className="absolute w-px h-5 bg-secondary-700"></div>
                      )} */}
                    <CompactOddsCell
                      alignment="home"
                      winner={
                        winner === "home" ? props.game.homeTeam : undefined
                      }
                      status={delayStatus ? prevStatus : props.game.status}
                      score={props.game.homeScore}
                      odd={buildOdd(
                        props.displayingOdds,
                        GameAlignmentType.Home,
                        props.game.odds
                      )}
                      againstOdd={buildOdd(
                        props.displayingOdds,
                        GameAlignmentType.Away,
                        props.game.odds
                      )}
                      game={props.game}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${flip.cardBack} rounded-xl`}>
            <Icon name="moneyline" size="xl" />
            {/* <Lottie
                key={Date.now()}
                loop={false}
                animationData={animationData}
                play={flipped}
                style={{ width: 100, height: 100 }}
              /> */}
          </div>
        </div>
      </div>
    </Link>
  );
}

interface CompactOddsCellProps {
  status: GameStatus;
  winner?: TeamBasic | null;
  score?: number;
  odd: Odd2 | undefined;
  againstOdd: Odd2 | undefined;
  game: ScoreboardGame;
  alignment: "away" | "home";
  onClick?: () => void;
}

function CompactOddsCell(props: CompactOddsCellProps) {
  const { oddsFormat } = useOddsFormat();
  const [prevScore, setPrevScore] = React.useState<number | undefined>(
    undefined
  );
  const [currentScore, setCurrentScore] = React.useState<number | undefined>(
    props.score
  );
  const [animateScore, setAnimateScore] = React.useState(false);

  React.useEffect(() => {
    if (props.score !== undefined && props.score !== currentScore) {
      if (currentScore !== undefined && props.score !== currentScore) {
        setAnimateScore(true);
        setTimeout(() => setAnimateScore(false), 500);
      }
      setPrevScore(currentScore);
      setCurrentScore(props.score);
    }
  }, [props.score]);

  const pickAnimationElement = useRef<HTMLDivElement>(null);
  const { addPick, getPickStatus, changePickAlignment, removePick } =
    useContext(PickSlipContext);
  const [addingPick, setAddingPick] = React.useState(false);
  const odd =
    props.odd === undefined ||
    props.odd.value === 0 ||
    props.odd.value === null ||
    props.odd.payout === null
      ? undefined
      : formatOdd(oddsFormat, props.odd);
  // console.log("odd from compact cell", props.odd);
  const exception = isGameException(props.status);
  const closed = isGameClosed(props.status);
  const addPickDetails: RecentPick | undefined =
    props.odd && props.againstOdd
      ? {
          type: lineToPickType(props.odd.type, props.odd.alignment),
          alignment: props.odd.alignment,
          sport_type: toSportType(props.game.league),
          game: {
            global_game_id: props.game.id,
            date_time_utc: props.game.dateTime,
            status: props.game.status,
            platform: props.game.platform,
            teams: {
              0: {
                id: props.game.awayTeam.id,
                name: props.game.awayTeam.name,
                location: props.game.awayTeam.location,
                abbreviation: props.game.awayTeam.abbreviation,
                primary_color: props.game.awayTeam.primaryColor,
                logoUrl: props.game.awayTeam.logoUrl,
                slug: `${props.game.awayTeam.location}-${props.game.awayTeam.name}`,
              },
              1: {
                id: props.game.homeTeam.id,
                name: props.game.homeTeam.name,
                location: props.game.homeTeam.location,
                abbreviation: props.game.homeTeam.abbreviation,
                primary_color: props.game.homeTeam.primaryColor,
                logoUrl: props.game.homeTeam.logoUrl,
                slug: `${props.game.homeTeam.location}-${props.game.homeTeam.name}`,
              },
            },
          },
          odds: {
            0: props.alignment === "away" ? props.odd : props.againstOdd,
            1: props.alignment === "home" ? props.odd : props.againstOdd,
          },
        }
      : undefined;

  const pickStatus = getPickStatus(addPickDetails);

  const disabled =
    props.status === "InProgress" ||
    closed ||
    !odd ||
    pickStatus.created ||
    pickStatus.opposing.created;

  function handlePickClick(e: any) {
    e.preventDefault();
    e.stopPropagation();
    console.log("pick status:", pickStatus);

    if (pickStatus.created) {
      return;
    } else if (pickStatus.added) {
      removePick(addPickDetails);
      return;
    } else if (pickStatus.opposing.added) {
      changePickAlignment(addPickDetails);
      return;
    } else if (!pickStatus.added && !pickStatus.opposing.added) {
      addPick(addPickDetails, pickAnimationElement);

      setAddingPick(true);
      setTimeout(() => {
        setAddingPick(false);
        return;
      }, 700);
    }
  }

  const inPossession = getInPossession(props.game, props.alignment);
  return (
    <div className="relative" ref={pickAnimationElement}>
      {inPossession && (
        <div className="top-4 bg-secondary-300 w-1 h-1 rounded-full absolute -right-1"></div>
      )}

      <Button
        custom
        disabled={disabled}
        className={`flex rounded-md relative z-0 overflow-hidden gap-1 items-center p-2 justify-center text-secondary-200 group ${
          props.status === "InProgress" || !odd
            ? "bg-transparent text-title-bold pl-0 pr-0 justify-between"
            : closed && props.winner
            ? "bg-dark text-title-bold text-light"
            : closed && !props.winner
            ? "bg-transparent text-title-bold"
            : pickStatus.created || pickStatus.opposing.created
            ? "bg-transparent text-title-bold"
            : `${BUTTON_VARIANCE.standard.color.filled.secondary} bg-surface-600 text-title-bold`
        } w-[5.0625rem] h-9 data-[disabled="true"]:bg-transparent ${
          (pickStatus.added || addingPick) && props.status === "Scheduled"
            ? "!bg-primary"
            : ""
        }
        ${disabled ? "pointer-events-none" : ""}
        `}
        data-disable-nprogress={true}
        onClick={handlePickClick}
      >
        {odd === undefined ? (
          <div className="w-full text-center text-label-lg-bold text-secondary-600">
            TBD
          </div>
        ) : props.status === "Scheduled" || exception ? (
          <>
            <span className="normal-case">
              {odd.type === 0 ? odd.payout : odd?.value}
            </span>
            <div
              className={`text-grey text-label-lg-bold group-hover:text-secondary-200 ${
                (pickStatus.added || addingPick) && props.status === "Scheduled"
                  ? "text-secondary-200"
                  : ""
              }`}
            >
              {odd.type === 0 ? undefined : odd.payout}
            </div>
          </>
        ) : props.status === "InProgress" ? (
          <>
            <div className="flex justify-between w-full items-center relative">
              <span className="normal-case">
                {odd.type === 0 ? odd.payout : odd?.value}
              </span>
              <div className="w-px h-5 bg-secondary-700"></div>
              <div className="relative flex justify-center items-center w-6 h-[1.15rem]">
                {animateScore ? (
                  <>
                    <div
                      className={`text-title-bold w-6 absolute inset-0 animate-exitToTop z-10`}
                    >
                      {prevScore}
                    </div>
                  </>
                ) : (
                  <>
                    {/* <div className={`text-title-bold w-6`}>{currentScore}</div> */}
                    <div
                      className={`text-title-bold w-6 absolute inset-0 animate-enterFromBottom z-10`}
                    >
                      {currentScore}
                    </div>
                  </>
                )}
                {/* <span className="text-title-bold w-6 absolute inset-0">{currentScore}</span> */}

                {/* <span className="text-title-bold w-6">{currentScore}</span> */}
              </div>
            </div>
          </>
        ) : (
          <>
            {props.winner ? (
              <>
                <TeamLogo
                  custom
                  className="-top-[125%] -left-1/4 -z-[1] absolute opacity-25 w-[150%] max-w-none pointer-events-none"
                  team={props.winner}
                />
                <span className="text-light">{props.score}</span>
              </>
            ) : (
              <span className="text-dark">{props.score}</span>
            )}
          </>
        )}
      </Button>
      {pickStatus.created && props.status === "Scheduled" && (
        <CreatedPickTooltip>You picked this odd</CreatedPickTooltip>
      )}
    </div>
  );
}
function Placeholder({ view }: { view: "slider" | "grid" }) {
  return (
    <div
      className={`flex flex-col gap-2 bg-surface shadow-elevation-400 mr-4 p-4 rounded-xl min-w-[200px] h-[132px] ${
        view === "grid"
          ? "scale-[0.80] min-[370px]:scale-[0.825] min-[380px]:scale-[0.85] min-[390px]:scale-[0.875] min-[400px]:scale-[0.9] min-[410px]:scale-[0.925] min-[420px]:scale-[0.95] min-[440px]:scale-100"
          : undefined
      } ${
        view === "slider" ? "mr-4" : undefined
      } origin-top-left w-[200px] h-[132px] rounded-xl`}
    >
      <div className="flex justify-between">
        <div className="bg-surface-400 rounded-[10px] w-16 h-[14px] animate-pulse"></div>
        <div className="bg-surface-400 rounded-[10px] w-10 h-[14px] animate-pulse"></div>
      </div>
      <div className="flex justify-between gap-2">
        <div className="bg-surface-400 rounded-full w-9 h-9 animate-pulse"></div>
        <div className="bg-surface-400 rounded-[10px] w-10 h-9 animate-pulse"></div>
        <div className="bg-surface-400 rounded-[10px] w-20 h-9 animate-pulse"></div>
      </div>
      <div className="flex justify-between gap-2">
        <div className="bg-surface-400 rounded-full w-9 h-9 animate-pulse"></div>
        <div className="bg-surface-400 rounded-[10px] w-10 h-9 animate-pulse"></div>
        <div className="bg-surface-400 rounded-[10px] w-20 h-9 animate-pulse"></div>
      </div>
    </div>
  );
}
GameCardCompact.Placeholder = Placeholder;
export default GameCardCompact;
