"use client";
import React, { forwardRef } from "react";
import Icon from "@/components/primitives/icon";
import "@splidejs/react-splide/css/core";
import { ALL_SPORT_OPTIONS_DETAILED, ODD_OPTIONS } from "@/data/sports/options";
import GameCardCompact from "../game/card/compact";
import Button, { BUTTON_VARIANCE } from "../primitives/button";
import { OddVariant, Sport, SportMetaData } from "common";
import { ScoreboardGameBasic } from "common";
import {
  convertDateToStringWithoutTime,
  formatDate,
  toISOStartOfDay,
} from "@/utils";
import { useWebSocketMessage } from "@/utils/useWebSocketMessage";
import useFetch from "@/utils/fetch";
import Tabs from "../primitives/tabs";
import { SPORTS, createSportsOptions } from "@/utils/sports";
import useLocalDate from "@/utils/useLocalDate";
import GameCardCompactSkeleton from "../game/card/compact-skeleton";

export type ScoreboardView = "slider" | "grid";

interface ScoreboardProps {
  view?: ScoreboardView;
}

export default function ScoreboardSkeleton(props: ScoreboardProps) {
  return (
    <section className="pt-4 desktop-grid:pt-6 relative z-10 scoreboard">
      <div className="container">
        <div className="flex flex-row">
          <h1 className="hidden min-[600px]:flex text-headline-lg uppercase items-center gap-1 mr-4">
            <Icon name="flash" size="lg" className="fill-primary" />
            Scoreboard
          </h1>

          <div
            className="scale-[1] origin-left flex flex-row gap-2 justify-between w-full"
          >
            <div className="flex flex-row gap-2 w-full desktop-grid:w-auto">
              <div className="min-w-[180px] min-[600px]:min-w-0 w-full desktop-grid:w-auto">
                <div className="h-11 min-w-[180px] w-full bg-white p-3 rounded-md flex flex-row gap-2 justify-between items-center animate-pulse">
                </div>
              </div>
              <div className="hidden desktop-grid:block min-w-[146px] min-[600px]:min-w-0">
                <div className="h-11 w-full bg-white animate-pulse"></div>
              </div>
              <div className="hidden min-[1000px]:flex">
                <div className="h-11 w-[254px] bg-secondary-700 p-1 rounded-lg flex flex-row gap-1 justify-between items-center">
                  <div className="w-[104px] bg-white rounded-lg h-full animate-pulse"></div>
                </div>
              </div>
            </div>

            <div className="hidden min-[360px]:flex min-[600px]:hidden">
            <div className="h-11 w-[100px] bg-secondary-700 p-1 rounded-lg flex flex-row gap-1 justify-between items-center">
                <div className="w-12 bg-white rounded-lg h-full animate-pulse"></div>
              </div>
            </div>
          </div>

          <div className="flex gap-4 justify-end">
            {props.view === "slider" && (
              <></>
              // <ScoreboardArrows
              //   slider={slider}
              //   view={view}
              //   missingMatches={missingMatches}
              // />
            )}

            <div className="hidden min-[600px]:flex">
              <div className="h-11 w-[100px] bg-secondary-700 p-1 rounded-lg flex flex-row gap-1 justify-between items-center">
                <div className="w-12 bg-white rounded-lg h-full animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="desktop-grid:hidden container mt-1 min-[360px]:mt-2 min-[428px]:mt-3 min-[600px]:mt-4 flex flex-row origin-left scale-100">
        <div className="h-11 w-full bg-secondary-700 p-1 rounded-lg flex flex-row gap-1 justify-between items-center">
          <div className="w-[20%] bg-white rounded-lg h-full animate-pulse"></div>
        </div>
      </div>
      <div className="flex mt-1 min-[360px]:mt-2 min-[428px]:mt-3 min-[600px]:mt-4 w-full px-3 min-[1000px]:hidden">
        <div className="h-11 w-full bg-secondary-700 p-1 rounded-lg flex flex-row gap-1 justify-between items-center">
          <div className="w-[104px] bg-white rounded-lg h-full animate-pulse"></div>
        </div>
      </div>
      <div
        className={`mt-4 ${
          props.view === "slider" ? "mb-10" : "mb-6"
        } container`}
      >
        {props.view === "slider" ? (
          <>
            <div className="flex flex-row overflow-hidden pb-16">
              <GameCardCompactSkeleton view="slider" />
              <GameCardCompactSkeleton view="slider" />
              <GameCardCompactSkeleton view="slider" />
              <GameCardCompactSkeleton view="slider" />
              <GameCardCompactSkeleton view="slider" />
              <GameCardCompactSkeleton view="slider" />
            </div>
            <div className="-mt-16"></div>
          </>
        ) : (
          <>
            <div
              className={`grid grid-cols-2 min-[640px]:grid-cols-3 min-[860px]:grid-cols-4 min-[1096px]:grid-cols-5 gap-x-6 min-[370px]:gap-x-4 min-[400px]:gap-x-4 gap-y-1 min-[400px]:gap-y-4 min-[420px]:gap-y-6 min-[440px]:gap-y-8 justify-between `}
            >
              <>
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
                <GameCardCompactSkeleton view="grid" />
              </>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
