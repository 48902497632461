"use client";
import React from "react";
import Select from "@/components/primitives/select";
import Icon from "@/components/primitives/icon";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import { ODD_OPTIONS } from "@/data/sports/options";
import GameCardCompact from "../game/card/compact";
import Button, { BUTTON_VARIANCE } from "../primitives/button";
import {
  OddVariant,
  Scoreboard as ScoreboardResponse,
  Sport,
  SportMetaData,
  ScoreboardGame,
  SEASON_DURATION,
  getDefaultYear,
  toLineType,
} from "common";

import { ScoreboardGameBasic } from "common";

import Tabs from "../primitives/tabs";
import { SPORTS, createSportsOptions } from "@/utils/sports";
import useLocalDate from "@/utils/useLocalDate";
import GameCardCompactSkeleton from "../game/card/compact-skeleton";
import ScoreboardSportsTabs from "./scoreboard-sports-tabs";
import { createScoresSlug } from "@/utils/url";

import LeagueKickoff from "./league-kickoff";
import useMediaQuery from "@/utils/useMediaQuery";
import { useScoreboard } from "@/utils/providers/scoreboard";
import ScoreboardSkeleton from "./scoreboard-skeleton";
import { toISOStartOfDay } from "@/utils";
import NotFound from "../primitives/not-found";
import { usePathname } from "next/navigation";
const GameCardCompactMemo = React.memo(
  GameCardCompact,
  (prevProps, nextProps) => {
    return prevProps.game === nextProps.game;
  }
);
export type ScoreboardView = "slider" | "grid";
interface ScoreboardProps {
  sport?: Sport;
  yesterday?: ScoreboardGameBasic[];
  view?: ScoreboardView;
}

export default function Scoreboard(props: ScoreboardProps) {
  const {
    games,
    gamesBySport,
    gameCounts,
    isLoading,
    isError,
    dateOptions,
    selectedDate,
    setSelectedDate,
  } = useScoreboard();

  // const [data, setData] = React.useState<ApiResponse<ScoreboardResponse>>(
  //   props.res
  // );
  // console.log("scoreboard game length:", data?.data?.games.length);
  // const [today, setToday] = React.useState<string>(props.date);
  // const [date, setDate] = React.useState<string>(props.date);
  // console.log("scoreboard date:", date);
  // console.log("scoreboard today:", today);
  const pathname = usePathname();
  const [view, setView] = React.useState<ScoreboardView>(props.view || "grid");
  const [animate, setAnimate] = React.useState<boolean>(true);
  const [displayingOdds, setDisplayingOdds] =
    React.useState<OddVariant>("moneyline");
    
  const { date: localDate } = useLocalDate();
  // const [shouldFetch, setShouldFetch] = React.useState<boolean>(false);
  const [selectedSport, setSelectedSport] = React.useState<Sport>(
    props.sport || "all"
  );
  // const [gameCounts, setGameCounts] = React.useState<GameCounts>(
  //   defaultGameCounts()
  // );

  // Apply scoreboard updates.
  // useScoreboardUpdate(data, setData);

  // Group the data only once.
  // const groupedData = React.useMemo(() => {
  //   if (!data.data) return null;
  //   return groupGamesByLeague(data.data.games);
  // }, [data]);

  // Get the date options only if today changes.
  // const combinedDateOptions = React.useMemo(() => {
  //   return createDateOptions(today);
  // }, [today]);

  const slider = React.useRef<Splide>(null);
  const [sliderStartGradient, setSliderStartGradient] =
    React.useState<boolean>(false);
  const [sliderEndGradient, setSliderEndGradient] =
    React.useState<boolean>(true);

  const isDesktop = useMediaQuery("(min-width: 1000px)");
  const noGrid = useMediaQuery("(max-width: 359px)");
  

  // If the screen size changes, re-render.
  React.useEffect(() => {
    switch (pathname) {
      case "/":
      case "/nfl":
      case "/nba":
      case "/mlb":
      case "/nhl":
      case "/how-moneyline-works":
        if (noGrid) {
          setView("slider");
        } else {
          setView("grid");
        }
        break;
      default:
        setView("slider");
        break;
    }
  }, [isDesktop, noGrid]);


  let noGames: boolean = !games || !games.length;
  if (gamesBySport && selectedSport !== "all") {
    if (!gamesBySport[selectedSport] || gamesBySport[selectedSport]?.length === 0) {
      noGames = true;
    }
  }
  const noMoreGames: boolean = games && games.length < 6 ? true : false;
  

  let showCountdown: boolean = false;
  if (selectedSport !== "all") {
    if (
      !(selectedSport in gamesBySport) ||
      gamesBySport[selectedSport]?.length === 0
    ) {
      const seasonDuration = SEASON_DURATION[selectedSport as string];
      if (selectedDate) {
        const date = new Date(selectedDate);
        const beforeSeasonStart =
          date >= new Date(seasonDuration.end) &&
          date < new Date(seasonDuration.start);
        showCountdown = beforeSeasonStart;
      }
    }
  }

  if (isLoading) {
    return (
      <>
        <ScoreboardSkeleton view="grid" />

        {/* <div className="block min-[1000px]:hidden">
        <ScoreboardSkeleton view="grid" />
      </div>
      <div className="hidden min-[1000px]:block">
        <ScoreboardSkeleton view="slider" />
      </div> */}
      </>
    );
  }
  return (
    <section className="pt-3 desktop-grid:pt-6 relative z-10 scoreboard">
      <div className="container">
        <div className="flex flex-row">
          <h1 className="hidden min-[600px]:flex text-headline-lg uppercase items-center gap-1 mr-4">
            <Icon name="flash" size="lg" className="fill-primary" />
            Scoreboard
          </h1>

          <div
            className="scale-[1] origin-left flex flex-row gap-2 justify-between w-full"
            // style={{
            //   transform: `scale(${
            //     currentScreenWidth < 480 ? currentScreenWidth / 480 : 1
            //   })`,
            // }}
          >
            <div className="flex flex-row gap-2 w-full desktop-grid:w-auto">
              <div className="min-w-[180px] min-[600px]:min-w-0 w-full desktop-grid:w-auto">
                <Select
                  label="Date"
                  height={16}
                  options={dateOptions}
                  defaultValue={dateOptions?.[1]?.value}
                  value={selectedDate}
                  onValueChange={(v) => setSelectedDate(v)}
                  icon="calendar"
                  block
                  align="end"
                />
              </div>
              {!props.sport && (
                <div className="hidden desktop-grid:block min-w-[146px] min-[600px]:min-w-0">
                  <Select
                    label="Sport"
                    value={selectedSport}
                    options={createSportsOptions(gameCounts, true)}
                    onValueChange={(v) => setSelectedSport(v as Sport)}
                  />
                </div>
              )}
              <div className="hidden min-[1000px]:flex">
                <Tabs
                  label="Toggle Odds"
                  variant="pill"
                  items={ODD_OPTIONS}
                  value={displayingOdds}
                  onValueChange={(v) => setDisplayingOdds(v as OddVariant)}
                />
              </div>
            </div>

            <div className="hidden min-[360px]:flex min-[600px]:hidden">
              <Tabs
                label="Toggle View"
                variant="pill"
                items={[
                  {
                    label: <Icon name="layout-horizontal" />,
                    value: "slider",
                  },
                  {
                    label: <Icon name="layout-grid" size="sm" />,
                    value: "grid",
                  },
                ]}
                value={view}
                onValueChange={(v) => setView(v as ScoreboardView)}
              />
            </div>

            {/* <SplideSlide>
              <Select
                label="Odds"
                options={ODD_OPTIONS}
                defaultValue={ODD_OPTIONS[0].value}
                onValueChange={(v) => setDisplayingOdds(v as OddVariant)}
              />
            </SplideSlide> */}
          </div>

          <div className="flex gap-4 justify-end">
            {view === "slider" && (
              <></>
              // <ScoreboardArrows
              //   slider={slider}
              //   view={view}
              //   missingMatches={missingMatches}
              // />
            )}

            <div className="hidden min-[600px]:flex ml-2">
              <Tabs
                label="Toggle View"
                variant="pill"
                items={[
                  {
                    label: <Icon name="layout-horizontal" />,
                    value: "slider",
                  },
                  {
                    label: <Icon name="layout-grid" size="sm" />,
                    value: "grid",
                  },
                ]}
                value={view}
                onValueChange={(v) => setView(v as "slider" | "grid")}
              />
            </div>
          </div>
        </div>
      </div>
      {(props.sport === "all" || props.sport === undefined) && (
        <div className="px-4 mt-3 container flex flex-row desktop-grid:hidden">
          <ScoreboardSportsTabs
            label="toggle sport"
            variant="pill"
            block
            value={selectedSport}
            items={[
              {
                label: "All",
                value: "all",
                count: gameCounts.all,
              },
              {
                label: "NFL",
                value: "nfl",
                count: gameCounts.nfl,
              },
              {
                label: "NBA",
                value: "nba",
                count: gameCounts.nba,
              },
              {
                label: "MLB",
                value: "mlb",
                count: gameCounts.mlb,
              },
              {
                label: "NHL",
                value: "nhl",
                count: gameCounts.nhl,
              },
            ]}
            onValueChange={(v) => setSelectedSport(v as Sport)}
          />
        </div>
      )}

      {/* <div
        className="desktop-grid:hidden container px-4 mt-4 flex flex-row origin-left scale-100"
        style={{
          transform: `scale(${
            currentScreenWidth < 440 ? currentScreenWidth / 440 : 1
          })`,
        }}
      >
        <Tabs
          label="toggle sport"
          variant="pill"
          buttonWidth={currentScreenWidth > 440 ? undefined : 80}
          block={currentScreenWidth > 440}
          value={selectedSport}
          items={[
            {
              label: "All",
              value: "all",
              count: `${gameCounts.all}`,
            },
            {
              label: "NFL",
              value: "nfl",
              count: `${gameCounts.nfl}`,
            },
            {
              label: "NBA",
              value: "nba",
              count: `${gameCounts.nba}`,
            },
            {
              label: "MLB",
              value: "mlb",
              count: `${gameCounts.mlb}`,
            },
            {
              label: "NHL",
              value: "nhl",
              count: `${gameCounts.nhl}`,
            },
          ]}
          onValueChange={(v) => setSelectedSport(v as Sport)}
        />
      </div> */}
      <div className="flex mt-3 w-full px-4 min-[1000px]:hidden">
        <Tabs
          label="Toggle Odds"
          variant="pill"
          block
          items={ODD_OPTIONS}
          value={displayingOdds}
          onValueChange={(v) => setDisplayingOdds(v as OddVariant)}
        />
      </div>
      <div className={`mt-4 mb-6 container`}>
        {view === "slider" ? (
          <>
            <Splide
              hasTrack={false}
              ref={slider}
              options={{
                rewind: false,
                autoWidth: true,
                pagination: false,
                arrows: false,
                drag: "free",
                trimSpace: false,
                // gap: isNarrowScreen && view === "slider" ? 0 : "1rem",
                waitForTransition: false,
                padding: { left: "0.75rem", right: "0.75rem" },
              }}
              onMove={() => {
                setSliderEndGradient(true);
                setSliderStartGradient(true);
              }}
              onDrag={() => {
                setSliderEndGradient(true);
                setSliderStartGradient(true);
              }}
              onDragged={(splide) => {
                setTimeout(() => {
                  const index = splide?.Components.Controller.getIndex();
                  if (index === 0) {
                    setSliderStartGradient(false);
                  }
                }, 1000);
              }}
              onVisible={(splide, Slide) => {
                if (Slide.index === 0) {
                  setSliderStartGradient(false);
                }
                if (games && Slide.index === games.length - 1) {
                  setSliderEndGradient(false);
                }
              }}
              onHidden={(splide, Slide) => {
                if (Slide.index === 0) {
                  setSliderStartGradient(true);
                }
                if (games && Slide.index === games.length - 1) {
                  setSliderEndGradient(true);
                }
              }}
              onMounted={(splide) => {
                const lastSlideIsVisible =
                  games &&
                  splide.Components.Slides.getAt(
                    games.length - 1
                  )?.slide.classList.contains("is-visible");
                games && setSliderEndGradient(!lastSlideIsVisible);
              }}
              className="-ml-3 -mr-3"
            >
              <div className="relative overflow-hidden">
                <div
                  className={`max-md:hidden absolute -left-[140px] w-[241px] h-[180px] top-[-16px] z-[2] bg-[linear-gradient(90deg,#F4F4F6_65.15%,rgba(244,244,246,0.00)100%)] pointer-events-none transition-opacity ${
                    sliderStartGradient &&
                    (selectedSport === "mlb" || selectedSport === "all")
                      ? "opacity-100"
                      : "opacity-0"
                  }`}
                ></div>

                <div
                  className={`max-md:hidden absolute -right-[140px] w-[241px] h-[180px] top-[-16px] z-[2] bg-[linear-gradient(270deg,#F4F4F6_65.15%,rgba(244,244,246,0.00)100%)] pointer-events-none transition-opacity ${
                    sliderEndGradient &&
                    (selectedSport === "mlb" || selectedSport === "all")
                      ? "opacity-100"
                      : "opacity-0"
                  }`}
                ></div>

                <SplideTrack className="-mt-6 pt-6 pb-16 ">
                  {isLoading ? (
                    <>
                      <SplideSlide>
                        <GameCardCompact.Placeholder view="slider" />
                      </SplideSlide>
                      <SplideSlide>
                        <GameCardCompact.Placeholder view="slider" />
                      </SplideSlide>
                      <SplideSlide>
                        <GameCardCompact.Placeholder view="slider" />
                      </SplideSlide>
                      <SplideSlide>
                        <GameCardCompact.Placeholder view="slider" />
                      </SplideSlide>
                      <SplideSlide>
                        <GameCardCompact.Placeholder view="slider" />
                      </SplideSlide>
                      <SplideSlide>
                        <GameCardCompact.Placeholder view="slider" />
                      </SplideSlide>
                    </>
                  ) : showCountdown ? (
                    <LeagueKickoff
                      sport={selectedSport}
                      year={getDefaultYear(selectedSport)}
                      dateTime={SEASON_DURATION[selectedSport].start}
                    />
                  ) : (
                    <>
                      {games &&
                        !isError &&
                        games.filter(
                          (game) =>
                            (game.league === selectedSport || selectedSport === "all"
                        )).map((game, i) => (
                          <SplideSlide key={`${game.id}-${i}`} className="pt-4">
                            <GameCardCompact
                              game={game}
                              displayingOdds={toLineType(displayingOdds as string)}
                              view={view}
                            />
                          </SplideSlide>
                        ))}
                      <div className="mt-4 w-full rounded-xl border-2 border-secondary-700 h-[132px] bg-transparent flex justify-center items-center min-w-[300px] mr-4">
                        <div className="text-headline-lg text-secondary-600 uppercase flex flex-col items-center justify-center gap-2">
                          <Icon
                            name="target-miss"
                            size="xl"
                            className="fill-secondary-600"
                          />
                          <span>
                            {noGames ? "No games found" : "No other games"}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </SplideTrack>
              </div>
            </Splide>
            <div className="-mt-16"></div>
          </>
        ) : (
          <>
            {Object.entries(gamesBySport || {})
              .filter(
                ([sport, gameIndices]) =>
                  (selectedSport === "all" || sport === selectedSport
              ) && gameIndices && gameIndices.length > 0)
              .map(([sport, gameIndices], groupIndex) => (
                <div className="flex flex-col gap-2" key={sport}>
                  <ScoreGroup hide={noGames} sport={sport as Sport} selectedDate={selectedDate}>
                    <div
                      className={`grid grid-cols-2 min-[640px]:grid-cols-3 min-[860px]:grid-cols-4 min-[1096px]:grid-cols-5 gap-x-6 min-[370px]:gap-x-4 min-[400px]:gap-x-4 gap-y-1 min-[400px]:gap-y-4 min-[420px]:gap-y-6 min-[440px]:gap-y-8 justify-between pt-0`}
                    >
                      <>
                        {gameIndices?.map((index, i) => {
                          if (!games || !games.length) return null;
                          const game: ScoreboardGame = games[index];
                          return (
                            <div
                              key={`${games[index].id}-${i}`}
                              className="origin-top-left scale-100"
                              style={{
                                animation: animate
                                  ? `fadeIn 0.5s ease ${i * 0.10}s forwards`
                                  : "none",
                                opacity: "0",
                              }}
                            >
                              <div className="relative">
                                {(isLoading || !localDate) && (
                                  <div className="absolute inset-0 z-[1]">
                                    <GameCardCompactSkeleton view="grid" />
                                  </div>
                                )}
                                <div
                                  style={{
                                    opacity:
                                      isLoading || !localDate ? "0" : "100",
                                  }}
                                >
                                  <GameCardCompact
                                    key={`${game.id}-${i}`}
                                    game={game}
                                    displayingOdds={toLineType(displayingOdds as string)}
                                    view={view}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    </div>
                  </ScoreGroup>
                </div>
              ))}
            {showCountdown ? (
              <LeagueKickoff
                sport={selectedSport}
                year={getDefaultYear(selectedSport)}
                dateTime={SEASON_DURATION[selectedSport].start}
              />
            ) : undefined}
            {(noGames && !showCountdown) && (
              <div className="text-headline-lg text-secondary-600 uppercase flex flex-col items-center justify-center gap-2 w-full h-full py-8">
                <Icon
                  name="target-miss"
                  size="xl"
                  className="fill-secondary-600"
                />
                <span>No games found</span>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
}

function ScoreboardArrows({
  slider,
  missingMatches,
  view,
}: {
  slider: React.RefObject<Splide>;
  missingMatches: boolean;
  view: "slider" | "grid";
}) {
  return (
    <div className="splide__arrows gap-2 justify-end items-center z-[4] h-[44px] [grid-area:arrows] hidden min-[720px]:flex">
      <button
        disabled={missingMatches || view === "grid"}
        onClick={() => slider.current?.go("<")}
        className={`splide__arrow splide__arrow--prev ${BUTTON_VARIANCE.base} ${BUTTON_VARIANCE.circle.base} ${BUTTON_VARIANCE.circle.size.sm} ${BUTTON_VARIANCE.standard.color.filled.secondary} disabled:bg-surface-600 disabled:fill-secondary disabled:cursor-not-allowed`}
      >
        <Icon name="arrow-left" size="sm" />
      </button>
      <button
        disabled={missingMatches || view === "grid"}
        onClick={() => slider.current?.go(">")}
        className={`splide__arrow splide__arrow--next ${BUTTON_VARIANCE.base} ${BUTTON_VARIANCE.circle.base} ${BUTTON_VARIANCE.circle.size.sm} ${BUTTON_VARIANCE.standard.color.filled.secondary} disabled:bg-surface-600 disabled:fill-secondary disabled:cursor-not-allowed`}
      >
        <Icon name="arrow-right" size="sm" />
      </button>
    </div>
  );
}

export function ScoreGroup({
  sport,
  children,
  hide,
  status,
  noLink,
  selectedDate,
}: {
  sport: Sport;
  children: React.ReactNode;
  hide?: boolean;
  status?: string;
  noLink?: boolean;
  selectedDate?: string;
}) {
  const metadata: SportMetaData | undefined = SPORTS.find(
    (x) => x.id === sport
  );
  if (metadata) {
    return (
      <div className="mb-0 min-[380px]:mb-2 min-[420px]:mb-4 min-[440px]:mb-6">
        {!hide && (
          <div className="mb-4 ml-1">
            <div className="flex flex-row gap-2 items-center">
              <div className="inline-flex items-center justify-center text-title-lg-medium gap-1 py-1 pl-2 pr-2 outline-none bg-transparent text-secondary fill-secondary h-[26px]">
                <Icon
                  name={metadata.icon}
                  size="sm"
                  className="fill-secondary"
                />{" "}
                {status ? status : metadata.label}
              </div>
              <div className="h-[2px] w-full bg-[#DEE0E3]"></div>
              {!noLink && (
                <>
                  <Button
                    href={createScoresSlug(sport, selectedDate)}
                    variant="card"
                    className="!px-0"
                  >
                    View Scores
                  </Button>
                  <div className="h-[2px] w-8 bg-[#DEE0E3]"></div>
                </>
              )}
            </div>
          </div>
        )}
        {children}
      </div>
    );
  }
  return <></>;
}
